
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'

@Component({
})
export default class TaxInfoForm extends Vue {
    @Prop()
    formValue: any

    @Prop()
    loading: any

    formTaxAddress: any = {
        note: 'personal',
        title: '',
        firstName: '',
        lastName: '',
        taxId: '',
        address: '',
        address2: '',
        district: '',
        states: '',
        province: '',
        postcode: '',
        contactNo: ''
    }

    textFieldRules: any = {
        required: true
    }

    @Watch('formValue', { deep: true, immediate: true })
    async onFormValueChange (newVal: any): Promise<void> {
      if (newVal) this.formTaxAddress = newVal
    }

    private onSubmit (): void {
      this.$emit('save', this.formTaxAddress)
    }

    private onCancel (): void {
      this.$emit('onCancel')
    }

    get isCorporation () {
      return this.formTaxAddress.note === 'corporation'
    }

    get labelName () {
      return this.isCorporation ? 'ชื่อบริษัท *' : 'ชื่อ *'
    }
}
