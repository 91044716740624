
import { Component, Vue, Prop } from 'vue-property-decorator'

@Component({})
export default class ItemAddress extends Vue {
    @Prop()
    address: any

    @Prop()
    indexOfAddress: any

    // eslint-disable-next-line
    mapFullName (member: any): string {
        return (member.title || member.firstName || member.lastName) ? `${member.title} ${member.firstName} ${member.lastName}` : '-'
    }

    selectAddress (): void {
        this.$emit('selectAddress', this.address, this.indexOfAddress)
    }

    onEditClick (): void {
        this.$emit('onEditClick', this.address, this.indexOfAddress)
    }
}
