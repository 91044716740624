
/* eslint-disable @typescript-eslint/no-explicit-any */

import { Component, Prop, Vue } from 'vue-property-decorator'
import { mapState } from 'vuex'

@Component({
    computed: {
    ...mapState({
      storeInfo: (state: any) => state.Store.storeInfo,
      storeSetting: (state: any) => state.Store.storeSetting
    })
  }
})
export default class TaxInvoice extends Vue {
  storeInfo!: any

  storeSetting!: any

  formatDateTime!: any

  @Prop() order!: any

  @Prop() isPreview!: boolean

  salesViaHeadquarter = (
    // eslint-disable-next-line
    this.order.branchPos.unitId == 1117 || this.order.branchPos.unitId == 1143
  )

  get webUrl (): any {
    return this.storeSetting?.webUrl || ''
  }

  get previousPaid (): any {
    const previousData = { isCreditNote: false, sum: 0 }
    if (!this.order) return previousData
    if (this.order.payments.length > 1) {
      const beforeLastIndex = this.order.payments.length - 1
      for (let index = 0; index < beforeLastIndex; index++) {
        previousData.sum += this.order.payments[index].payAmount
      }
      return previousData
    }
    if (this.order?.payments.length === 1 && this.order?.payments[0]?.channel === 'CreditNote') {
      previousData.isCreditNote = true
      previousData.sum = this.order?.payments[0]?.payAmount
      return previousData
    }
    return previousData
  }

  get currentPaid (): any {
    return this.order.payments[this.order.payments.length - 1]
  }

  sumAmount = (items: Array<any>): any => items.reduce((sum: number, d) => sum + d.amount, 0)

  priceStepPricing = (items: Array<any>): any => items.reduce((sum: number, d) => sum + d.price, 0)

  showFullPriceFormat = (number: number): any => ((number).toFixed(2)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')

  calPriceBeforeVat = (price: number): number => (price * 100) / 107

  showProductName = (text: string): any => {
    const a = text.split('-')
    if (a[0].length > 25) {
      return [`${a[0].substring(0, 24) }...`, a[1]]
    }
    return [a[0], a[a.length - 1]]
  }
}
